import styled from "styled-components"

export const ErrorMessage = styled.p`
  color: #d0021b;
  line-height: 14px;
  font-size: 14px;
  margin: 0px !important;
  /* padding: 0px !important; */
  padding-top: 3px;
  padding-bottom: 3px;
`
