import { ApiUrl } from "../util/apiUrl"
import { getUser } from "./auth"

export const makeApiCall = (route, data) => {
  return fetch(`${ApiUrl}${route}`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: "Api-Key 18475794-de56-4ff0-a685-3d591fb81661",
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      return data
    })
    .catch(e => {
      return false
    })
}

export const testToken = () => {
  const user = getUser()
  return fetch(`${ApiUrl}/test-token?secret_token=${user.token}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      return data
    })
    .catch(e => {
      return false
    })
}

export const testPasswordToken = token => {
  return fetch(`${ApiUrl}/test-token?secret_token=${token}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      return data
    })
    .catch(e => {
      return false
    })
}
