import { Container } from "@material-ui/core"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { Link } from "gatsby"
import { Layout } from "../components/layout"
import styled from "styled-components"
import { validateEmail } from "../util/validateEmail"
import Breadcrumb from "../components/Breadcrumb"
import { Button } from "../components/Buttons/Buttons"
import { ErrorMessage } from "../components/Form/ErrorMessageText"
import { makeApiCall } from "../services/apiCall"
import { GFWSpinner } from "../components/spinner/spinner"

const breadcrumbData = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Forgotten password",
    url: "/forgotten-password",
  },
]

const ForgottenPassword = ({ data, location }) => {
  const [waiting, setWaiting] = useState(false)
  const [message, setMessage] = useState("")
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")

  function handleUpdate(c) {
    setEmail(c.target.value)
  }
  function handleSubmit() {
    //validate email

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.")
      return
    } else {
      setEmailError("")
    }
    setWaiting(true)
    //if email is valid, call the API and change page
    makeApiCall("/password-reset", { email }).then(data => {
      if (data) {
        setMessage("Password reset email sent. Please check your inbox.")
        navigate(`/forgotten-password-email-sent/?email=${email}`)
      } else {
        setMessage("Something went wrong. Please check your details.")
      }
      setWaiting(false)
    })
  }
  function handleEmailBlur() {}

  return (
    <Layout title={"Graduate Fashion Week - Sign Out Sucessful"}>
      <div style={{ backgroundColor: "#FAFAFA", width: "100%" }}>
        <Container maxWidth={"lg"}>
          <Breadcrumb data={breadcrumbData} />
        </Container>
      </div>
      <Container maxWidth={"sm"}>
        <Wrapper>
          <Heading>Oops… Forgotten your password?</Heading>
          <SubHeading>We will email you a link to reset your password and get you back online in a jiffy</SubHeading>
          <label style={{ width: "100%" }}>
            Email Address
            <input type="text" name="username" onChange={handleUpdate} onBlur={handleEmailBlur} value={email} />
          </label>
          <ErrorMessage style={{ alignSelf: "flex-start" }}>{emailError}</ErrorMessage>
          <br />
          <ButtonContainer>
            <div style={{ width: "100%", height: 24 }}>
              {waiting ? (
                <div style={{ margin: "auto", width: 10 }}>
                  {" "}
                  <GFWSpinner size={10} thickness={5} disableShrink />{" "}
                </div>
              ) : null}
            </div>
            <p style={{ marginBottom: 10, textAlign: "center" }}>{message}</p>

            {message === "" ? (
              <div onClick={() => handleSubmit()} style={{ width: "100%" }}>
                <Button>SEND RESET PASSWORD LINK</Button>
              </div>
            ) : (
              ""
            )}
          </ButtonContainer>
          <Link to={"/events/login"}>Back to sign in</Link>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default ForgottenPassword

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 85px auto 158px;
`

const Heading = styled.h2`
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 55px;
  text-align: center;
`

const SubHeading = styled.h2`
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
`

const ButtonContainer = styled.div`
  margin-bottom: 15px;
`
